import exception_en from "./resources/exception/exception_en.json";
import exception_pt_br from "./resources/exception/exception_pt_br.json";
import attachmentExceptionCodes from "./resources/exception/attachmentExceptionCodes";

export { attachmentExceptionCodes };

const attachmentI18n = {
    en: {
        ...exception_en
    },
    pt_br: {
        ...exception_pt_br
    },
};

export default attachmentI18n;
