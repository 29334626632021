import { ExceptionCodes } from "@wi-core/shared-blocks";


const attachmentExceptionCodes: ExceptionCodes = {
    //attendance_create_attachment
    "AT UC020 0001": "attendance_create_attachment.invalid_attachment_name",
    "AT UC020 0002": "attendance_create_attachment.invalid_attachment_type",
    "AT UC020 0003": "attendance_create_attachment.invalid_file_content",
    "AT UC020 0004": "attendance_create_attachment.invalid_file_name",
    "AT UC020 0005": "attendance_create_attachment.invalid_file_mime",
    "AT UC020 0006": "attendance_create_attachment.file_size_larger_than_allowed",
    "AT UC020 0007": "attendance_create_attachment.invalid_draft",
    "AT UC020 0008": "attendance_create_attachment.invalid_attendance",
    "AT UC020 0009": "attendance_create_attachment.invalid_license",

    //attendance_get_attachment
    "AT UC021 0001": "attendance_get_attachment.attachment_not_found",

    //attendance_delete_attachment
    "AT UC023 0001": "attendance_delete_attachment.attachment_not_found",
    "AT UC023 0002": "attendance_delete_attachment.invalid_code",
    "AT UC023 0003": "attendance_delete_attachment.attachment_in_use_in_a_attendance_cannot_be_deleted",

    //attendance_update_attachment
    "AT UC024 0001": "attendance_update_attachment.invalid_attachment_name",
    "AT UC024 0002": "attendance_update_attachment.invalid_attachment_type",
    "AT UC024 0003": "attendance_update_attachment.invalid_file_content",
    "AT UC024 0004": "attendance_update_attachment.invalid_file_name",
    "AT UC024 0005": "attendance_update_attachment.invalid_file_mime",
    "AT UC024 0006": "attendance_update_attachment.file_size_larger_than_allowed",
    "AT UC024 0007": "attendance_update_attachment.invalid_draft",
    "AT UC024 0008": "attendance_update_attachment.invalid_attendance",
    "AT UC024 0009": "attendance_update_attachment.invalid_license",
    "AT UC024 0010": "attendance_update_attachment.attachment_not_found",

    //schedule_create_attachment
    "SC UC180 0001": "schedule_create_attachment.invalid_attachment_name",
    "SC UC180 0002": "schedule_create_attachment.invalid_attachment_type",
    "SC UC180 0003": "schedule_create_attachment.invalid_file_content",
    "SC UC180 0004": "schedule_create_attachment.invalid_file_name",
    "SC UC180 0005": "schedule_create_attachment.invalid_file_mime",
    "SC UC180 0006": "schedule_create_attachment.file_size_larger_than_allowed",
    "SC UC180 0007": "schedule_create_attachment.invalid_draft",
    "SC UC180 0008": "schedule_create_attachment.invalid_appointment",
    "SC UC180 0009": "schedule_create_attachment.invalid_license",

    //schedule_get_attachment
    "SC UC181 0001": "schedule_get_attachment.attachment_not_found",

    //schedule_delete_attachment
    "SC UC183 0001": "schedule_delete_attachment.attachment_not_found",
    "SC UC183 0002": "schedule_delete_attachment.invalid_code",
    "SC UC183 0003": "schedule_delete_attachment.attachment_in_use_in_a_appointment_cannot_be_deleted",

    //schedule_update_attachment
    "SC UC184 0001": "schedule_update_attachment.invalid_attachment_name",
    "SC UC184 0002": "schedule_update_attachment.invalid_attachment_type",
    "SC UC184 0003": "schedule_update_attachment.invalid_file_content",
    "SC UC184 0004": "schedule_update_attachment.invalid_file_name",
    "SC UC184 0005": "schedule_update_attachment.invalid_file_mime",
    "SC UC184 0006": "schedule_update_attachment.file_size_larger_than_allowed",
    "SC UC184 0007": "schedule_update_attachment.invalid_draft",
    "SC UC184 0008": "schedule_update_attachment.invalid_appointment",
    "SC UC184 0009": "schedule_update_attachment.invalid_license",
    "SC UC184 0010": "schedule_update_attachment.attachment_not_found"
}

export default {
    ...attachmentExceptionCodes
};