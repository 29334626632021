import en from "./resources/en.json";
import pt_br from "./resources/pt_br.json";

import exception_en from "./resources/exception/exception_en.json";
import exception_pt_br from "./resources/exception/exception_pt_br.json";
import exceptionCodes from "./resources/exception/exceptionCodes";

export { exceptionCodes };

const i18n = {
    en: {
        ...en,
        ...exception_en
    },
    pt_br: {
        ...pt_br,
        ...exception_pt_br
    }
};

export default i18n;